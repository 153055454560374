import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import React from "react";

import { SectionTitle } from "../../../../components/Common/SectionTitle";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { Link } from "react-router-dom";

const Address = ({ dataGetProfile, register }) => {
  return (
    <Box>
      <Grid templateColumns={"repeat(2, 1fr)"}>
        <GridItem>
          <SectionTitle title={"Permanent Address"} />
          <LeftSideLabelInput
            label="Country"
            type="text"
            // value={dataGetProfile?.address[0]?.country}
            register={register}
            registerName={"permaCountry"}
          />
          <LeftSideLabelInput
            label="City"
            type="text"
            // value={dataGetProfile?.address[0]?.city}
            register={register}
            registerName={"permaCity"}
          />
          <LeftSideLabelInput
            label="State/Provience"
            type="text"
            // value={dataGetProfile?.address[0]?.state}
            register={register}
            registerName={"permaState"}
          />
        </GridItem>
        <GridItem>
          {/* <SectionTitle title={"Temporary Address"} />
          <LeftSideLabelInput
            label="Country"
            type="text"
            // value={dataGetProfile?.address[1]?.country}
            register={register}
            registerName={"temCountry"}
          />
          <LeftSideLabelInput
            label="City"
            type="text"
            register={register}
            registerName={"temCity"}
          />
          <LeftSideLabelInput
            label="State/Province"
            type="text"
            register={register}
            registerName={"temState"}
          /> */}
        </GridItem>

        <Flex gap="2rem" mt="3rem">
          <SaveButton />
          <Link to="/admin/profile">
            {" "}
            <CancelButton />
          </Link>
        </Flex>
      </Grid>
    </Box>
  );
};

export default Address;
