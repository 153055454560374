import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { usePostInventoryItemApi } from "../api/usePostInventoryItemApi";
import useMultipleImage from "views/Pages/Inventory/Add/component/useMultipleImage";

export const usePostInventoryItem = () => {
  const { postInventoryItemMutation } = usePostInventoryItemApi();

  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postInventoryItemMutation;
  const {
    file,
    handleUpload,
    handleClick,
    hiddenFileInput,
    countIndex,
  } = useMultipleImage();

  const toast = useToast();
  const toastIdRef = useRef();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
  } = useForm();

  const onSubmit = (values) => {
    console.log("value send for path", values);
    const formData = new FormData();
    formData.append("uuid", values.uuid);
    formData.append("special", values.special ?? false);
    formData.append("is_unlimited", values.is_unlimited ?? false);

    formData.append("name", values.name);
    formData.append("selling_price", values.selling_price);
    formData.append("cost_price", values.cost_price);
    formData.append("sku", values.sku);
    formData.append("upc", values.upc);
    file?.forEach((image) => {
      formData.append("image", image);
    });
    postInventoryItemMutation.mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "Updated",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        location.reload();
      }, 1000);
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postInventoryItemMutation.isSuccess,
    postInventoryItemMutation.isLoading,
    postInventoryItemMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
    isSuccess,
    file,
    handleUpload,
    handleClick,
    hiddenFileInput,
    countIndex,
  };
};
