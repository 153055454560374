import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Center,
  Image,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { PageTitle } from "components/Common/PageTitle";
import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import Document from "./Document";
import { globalStyles } from "../../../../theme/styles";
import Address from "./Address";
import Job from "./Job";
import Personal from "./Personal";
import useProfileDetails from "./useProfileDetails";
import ChangeEmail from "components/Common/ChangeEmail";

const ProfileDetails = () => {
  const {
    dataGetProfile,
    onSubmit,
    handleSubmit,
    register,
    uploadPhotoRef,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoFile,
    setBirthDate,
    birthDate,
    jobStartDate,
    setJobStartDate,
    control,
    setValue,
  } = useProfileDetails();

  const { isOpen, onOpen, onClose } = useDisclosure();
  
  // console.log("dd", dataGetProfile);

  return (
    <Box direction="column">
      <input
        type="file"
        hidden
        ref={uploadPhotoRef}
        onChange={uploadPhotoChangeHandler}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {" "}
        <Box h="97vh" bg={"white"}>
          <PageTitle title={"Profile Details"} />
          <HStack gap="4rem" p="2rem" pt={0}>
            <Box>
              <Flex alignItems="center" gap="1.2rem">
                <Box fontSize={"sm"}>Name</Box>
                <Flex ml="5.7rem" gap="1rem">
                  {" "}
                  <Input
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    type={"text"}
                    size="sm"
                    // value={dataGetProfile?.first_name}
                    placeholder="First Name"
                    {...register("first_name")}
                  />
                  <Input
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    type={"text"}
                    size="sm"
                    // value={dataGetProfile?.last_name}
                    placeholder="Last Name"
                    {...register("last_name")}
                  />
                </Flex>
              </Flex>

              <LeftSideLabelInput
                label="Contact"
                type="text"
                // value={dataGetProfile?.contact[0]?.value}
                register={register}
                registerName={"contact"}
              />
              <HStack>
                <LeftSideLabelInput
                  label="Email"
                  type="text"
                  value={dataGetProfile?.email}
                  disabled={true}
                  register={register}
                  registerName={"email"}
                />

                <Button
                  style={globalStyles.customStyle.defaultBtnStyle}
                  fontSize={"sm"}
                  onClick={onOpen}
                >
                  Change Email
                </Button>
              </HStack>
            </Box>
            <Box>
              <Box onClick={uploadPhotoOnClickHandler} cursor="pointer">
                <Center
                  w="12rem"
                  h="8rem"
                  borderTop={"1px dashed #808080"}
                  borderLeft={"1px dashed #808080"}
                  borderRight={"1px dashed #808080"}
                  borderTopRadius="8px"
                >
                  <Image
                    src={
                      uploadPhotoFile.length !== 0
                        ? URL.createObjectURL(uploadPhotoFile[0])
                        : dataGetProfile?.profile
                    }
                    w="13rem"
                    h="7.8rem"
                    borderTopRadius="8px"
                    objectFit={"cover"}
                  />
                </Center>
                <Center
                  w="12rem"
                  h="2rem"
                  border={"1px solid #808080"}
                  borderBottomRadius="8px"
                >
                  Upload Photo
                </Center>
              </Box>
            </Box>
          </HStack>

          
          <Tabs overflow={"hidden"}>
            <TabList
              id="scroll"
              variant="unstyled"
              gap={{ sm: "1rem", md: "6rem" }}
              overflowX={{ sm: "scroll", xl: "visible" }}
              overflowY="hidden"
              scrollBehavior="smooth"
              p="1rem"
              bg={"#F8F8F8"}
              borderTop="2px solid #EAEAEA"
              borderBottom="2px solid #EAEAEA"
            >
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Personal
              </Tab>

              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Address
              </Tab>
              {/* <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Job Details
              </Tab>
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Documents
              </Tab> */}
            </TabList>

            <TabPanels>
              <TabPanel p="2rem" pt={"0"}>
                <Personal
                  dataGetProfile={dataGetProfile}
                  register={register}
                  birthDate={birthDate}
                  setBirthDate={setBirthDate}
                  control={control}
                  setValue={setValue}
                />
              </TabPanel>

              <TabPanel p="2rem">
                <Address dataGetProfile={dataGetProfile} register={register} />
              </TabPanel>

              <TabPanel p="2rem" pt={"0"}>
                <Job
                  dataGetProfile={dataGetProfile}
                  register={register}
                  jobStartDate={jobStartDate}
                  setJobStartDate={setJobStartDate}
                />
              </TabPanel>

              <TabPanel>
                <Document />
              </TabPanel>
              
            </TabPanels>
          </Tabs>
        </Box>
      </form>

      {/* change email modal */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p="0px!important">
            <ChangeEmail onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProfileDetails;
