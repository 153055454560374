import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Flex,
  Text,
  Box,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { PageText } from "components/Common/PageText";
import { MdEdit, MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import { BiErrorAlt } from "react-icons/bi";
import { RiImageAddFill } from "react-icons/ri";

export default function ItemListTable({ data }) {
  const history = useHistory();
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  const [displayLightBox, setDisplayLightBox] = useState(false);
  const [imageSource, setImageSource] = useState([]);

  const handleItemEdit = (item) => {
    history.push({
      pathname: "/admin/inventory-item-edit",
      search: "?sku=" + item.sku,
      state: { sku: item.sku },
    });
  };

  //For image lightbox
  const handleLightBox = (images) => {
    setDisplayLightBox(!displayLightBox);
    var imgs = [];
    // images.forEach((image) => {
    //     imgs.push(image.image)
    // });
    // setImageSource([images[0].image])
    setImageSource([images]);
  };

  console.log("items detail",data);

  return (
    <>
      <FsLightbox toggler={displayLightBox} sources={imageSource} />
      <TableContainer borderTop="1px" borderColor="#D6D6D6">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th fontSize="md">ITEMS DETAILS</Th>
              {[
                "SKU",
                "UPC",
                "COST PRICE",
                "SELLING PRICE",
                "STOCK ON HAND",
              ].map((item, index) => {
                return (
                  // <Th isNumeric key={index}>
                  //   {item}
                  // </Th>
                  <Th >
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.length &&
              data?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Flex gap={2} align="center" ml={breakPoint[0] ? 0 : 6}>
                        <Box
                          border="1px"
                          cursor="pointer"
                          borderColor="blackAlpha.300"
                          p={2}
                          onClick={() => handleLightBox(item?.image_url)}
                        >
                          {item?.image_url ? (
                            <Image
                              boxSize="50px"
                              src={item?.image_url}
                              alt="Aalaya POS"
                              objectFit={"cover"}
                            />
                          ) : (
                            <RiImageAddFill color="lightgray" size="50px" />
                          )}
                        </Box>
                        <Text
                          fontWeight="semibold"
                          // w={"27rem"}
                          whiteSpace="normal"
                        >
                          <PageText text={item.name} style={{ marginLeft: '5px' }} />
                        </Text>
                      </Flex>
                    </Td>
                    <Td >
                      <PageText text={item.sku} />
                    </Td>
                    <Td >
                      <PageText text={item.upc ? item.upc : "--"} />
                    </Td>
                    <Td >
                      <PageText text={"NRS. " + item.cost_price} />
                    </Td>
                    <Td >
                      <PageText text={"NRS." + item.selling_price} />
                    </Td>
                    <Td >
                      <PageText
                        text={
                          item.is_unlimited
                            ? "Unlimited"
                            : item.closing_stock + " pcs"
                        }
                      />
                    </Td>
                    <Td>
                      <MdModeEdit
                        size="1.2rem"
                        cursor="pointer"
                        onClick={() => handleItemEdit(item)}
                      />
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
