import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetFloor = () => {
  async function getFloor() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/floor`);

    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getFloor",
    getFloor
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
