import {
  Box,
  Button,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import React, { useState, useEffect, useRef } from "react";
import RetailerList from "./RetailerList";
import RetailerCategory from "./RetailerCategory";
import RetailerBill from "./RetailerBill";
import { usePostClothOrder } from "./api/usePostClothOrder";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useGetBrand } from "./api/useGetBrand";
import { useNotify } from "common hooks/useNotify";

export default function RetaiItem() {
  const toast = useToast();
  const [invoiceId, setInvoiceId] = useState("");
  const { data } = useGetBrand();
  const { postClothMutation } = usePostClothOrder();
  const [stock, setStock] = useState(false);
  const [selected, setSelected] = useState(false);

  const { data: clothData, isSuccess, isError } = postClothMutation;
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [menuCategory, setMenuCategory] = useState("");
  const [clothId, setClothId] = useState("");
  const { onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  const { errorNotify } = useNotify();
  const history = useHistory();
  const checkboxRef = useRef();
  function handleItemChange(item) {
    if (item?.is_unlimited === false && item?.closing_stock === 0) {
      setStock(true);
    } else {
      const itemIndex = selectedItems.findIndex(
        (selectedItem) => selectedItem.uuid === item.uuid
      );

      if (itemIndex !== -1) {
        // Item already exists, increase the quantity
        const updatedItems = selectedItems.map((selectedItem) =>
          selectedItem.uuid === item.uuid ? { ...selectedItem } : selectedItem
        );
        setSelectedItems(updatedItems);
      } else {
        // Item doesn't exist, add it to the cart with quantity 1
        setSelectedItems([...selectedItems, { ...item, qty: 1 }]);
      }
    }
  }
  const [complimentry, setComplimentry] = useState(false);
  const handleCheckboxChange = (uuid, index) => {
    console.log("Xxx", uuid, index);
    // selectedItems?.map((item) => {
    //   if (uuid === item?.uuid) {
    //     setComplimentry(true);
    //   } else {
    //     setComplimentry(false);
    //   }
    // });
  };
  //post order of cloth
  const submitValues = () => {
    const Data = selectedItems.map((item) => {
      return {
        product: item?.uuid,
        quantity: item?.qty,
        unitprice: item?.selling_price,
        billAmount: item?.selling_price * item?.qty,
        discount_percentage:
          parseFloat(document.getElementById(`dis-${item?.uuid}`).value) ?? 0,
        complimentry: document.getElementById(`com-${item?.uuid}`).checked,
      };
    });

    if(Data.length===0){
      toast({
        title: "Order should contain at least one item",
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }else{
      const finalData = {
        invoice_uuid: invoiceId,
        items: Data,
      };
      // console.log("zzzzz", Data);
      postClothMutation.mutate(finalData);
    }


  };


  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "New Item Added successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });

      setInvoiceId(clothData?.data?.invoice_number);
      setClothId(clothData?.data?.uuid);

      if (invoiceId) {
        history.push(`/admin/edit/${clothId}`);
        onClose();
      }
    }
    if (isError) {
      toast({
        title: postClothMutation?.error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [postClothMutation.isSuccess, postClothMutation.isError, invoiceId]);
  console.log("xxu", selectedItems);
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  useEffect(() => {
    if (searchQuery) {
      setMenuCategory("");
    }
  }, [searchQuery]);
  const handleMenu = (val) => {
    setMenuCategory(val);
  };
  useEffect(() => {
    if (stock) {
      setStock(false);
      errorNotify("Item out of stock");
    }
  }, [stock]);

  return (
    <Box minH={"100vh"} bg={"white"}>
      <TopSearch
        selectedItems={selectedItems}
        submitValues={submitValues}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Box paddingTop={"4.5rem"}>
        {breakPoint[0] && (
          <Box p={"1rem"} borderBottom={"1px solid lightgray"} width={"100%"}>
            {data?.data?.map((item, index) => {
              return (
                <Button
                  height={"29px"}
                  fontSize={"14px"}
                  fontWeight={"normal"}
                  width={"90px"}
                  color={"#fff"}
                  borderRadius={"4px"}
                  // bg="#3AB689"
                  mb={"0.5rem"}
                  mr={"0.5rem"}
                  colorScheme={activeIndex === index ? "red" : "green"}
                  size="md"
                  onClick={() => {
                    setMenuCategory(item?.uuid);
                    setActiveIndex(index);
                  }}
                >
                  {item?.name}
                </Button>
              );
            })}
          </Box>
        )}
        <Box display={"flex"} width={"100%"} minH={"90vh"} gap={"1rem"}>
          <Box>
            <RetailerCategory
              data={data}
              setMenuCategory={setMenuCategory}
              handleMenu={handleMenu}
            />
          </Box>
          <Box
            display={"block"}
            justifyContent={"space-between"}
            // flexWrap={"wrap"}
            width={"100%"}
            height={"-webkit-fit-content"}
            ml={"3rem"}
          >
            {" "}
            <RetailerList
              handleItemChange={handleItemChange}
              menuCategory={menuCategory}
              searchQuery={searchQuery}
            />
          </Box>
          <Box
            minW={"25rem"}
            borderLeft={"1px solid lightgray"}
            height={"90vh"}
          >
            <RetailerBill
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              submitValues={submitValues}
              invoiceId={invoiceId}
              selected={selected}
              setSelected={setSelected}
              checkboxRef={checkboxRef}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
