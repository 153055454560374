import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetBrandList = () => {
  async function getBrandList() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/brand-list/v2`);

    return res.data?.data?.map(({ uuid: value, name: label, ...rest }) => ({
      value,
      label,
      ...rest,
    }));
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getBrandList",
    getBrandList
  );

  console.log("branddata",data);

  return {
    data,
  };
};
