import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useGetInsightsApi } from "./useGetInsightsApi";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { PageSubTitle } from "components/Common/PageSubTitle";

export default function Dashboard() {
  const { data } = useGetInsightsApi();

  const breakPoint = useMediaQuery("(max-width: 1200px)");
  const breakPointSmall = useMediaQuery("(max-width: 650px)");

  return (
    <Box>
      <TopSearch />
      
      <Box p={"1rem"} pt={"5rem"}>
        <PageSubTitle title={"Insights"} />
        <Box display={"flex"} mt={"1rem"} mb={"1rem"}>
          <Box
            p={"1rem"}
            gap={"1rem"}
            borderRadius={"0.5rem"}
            boxShadow={"md"}
            width={breakPointSmall[0] ? "50%" : "15rem"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mr={"2rem"}
          >
            <Text fontSize={"xl"}>
              Total<br></br>Orders
            </Text>
            <Text
              color={"#3AB689"}
              fontSize={breakPointSmall[0] ? "3xl" : "5xl"}
            >
              {data?.data?.todays_orders}
            </Text>
          </Box>

          <Box
            p={"1rem"}
            gap={"1rem"}
            borderRadius={"0.5rem"}
            boxShadow={"md"}
            width={breakPointSmall[0] ? "50%" : "18rem"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"xl"}>
              Total<br></br>Sales
            </Text>
            <Text
              color={"#3AB689"}
              fontSize={breakPointSmall[0] ? "3xl" : "5xl"}
            >
              Rs.{data?.data?.todays_sales["RS"]}
            </Text>
          </Box>

          {data?.data?.todays_sales["$"] != 0 ||
          data?.data?.todays_sales["$"] === "" ? (
            <Box
              p={"1rem"}
              gap={"1rem"}
              borderRadius={"0.5rem"}
              boxShadow={"md"}
              minW={"18rem"}
              ml={"2rem"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontSize={"xl"}>
                Total<br></br>Sales
              </Text>
              <Text color={"#3AB689"} fontSize={"4xl"}>
                ${data?.data?.todays_sales["$"]}
              </Text>
            </Box>
          ) : (
            ""
          )}
        </Box>

        <PageSubTitle title={"Top 5 Most Sold Today"} mt="2rem" />
        <Box mt={"1rem"} mb={"1rem"}>
          <TableContainer
            width={breakPoint[0] ? "100%" : "50%"}
            border={"1px solid lightgray"}
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>NAME</Th>
                  <Th>QUANTITY</Th>
                  {/* <Th>SALES(Nrs)</Th> */}
                  {/* <Th>SALES($)</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.top_selling_items?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item?.name}</Td>
                    <Td>{item?.quantity}</Td>
                    {/* <Td>{item.rs_amount}</Td> */}
                    {/* <Td>{item.dollar_amount}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <PageSubTitle title={"Top 5 Most Canceled Items This Week"} />
        <Box mt={"1rem"} mb={"1rem"}>
          <TableContainer
            width={breakPoint[0] ? "100%" : "50%"}
            border={"1px solid lightgray"}
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>NAME</Th>
                  <Th>QUANTITY</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.most_cancel_item?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item?.name}</Td>
                    <Td>{item?.count}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Box mt={"2rem"} mb={"1rem"}>
            <PageSubTitle title={"Top 5 Most Wasted Items This Week"} />
            <TableContainer
              width={breakPoint[0] ? "100%" : "50%"}
              border={"1px solid lightgray"}
              mb="1rem"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>NAME</Th>
                    <Th>QUANTITY</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.most_wasted_item?.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item?.name}</Td>
                      <Td>{item?.total_waste}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <Box mt={"2rem"} mb={"1rem"}>
            <PageSubTitle title={"Top 5 Low on Kitchen Stock"} />
            <TableContainer
              width={breakPoint[0] ? "100%" : "50%"}
              border={"1px solid lightgray"}
              mb="1rem"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>NAME</Th>
                    <Th>STOCKS</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.low_on_kitchen_stock?.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item?.name}</Td>
                      <Td>{item?.available_stock}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
