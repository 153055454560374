import { Box, Button, Text } from "@chakra-ui/react";
import { PageTitle } from "components/Common/PageTitle";
import React, { useEffect, useState } from "react";
import AvailaibleTable from "./AvailaibleTable";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { useGetFloor } from "./api/useGetFloor";
import { useGetTable } from "./api/useGetTable";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useQuery } from "react-query";
import { Loading } from "components/Common/Loading";

export default function TableList() {
  const [floorId, setFloorId] = useState("");
  const { data, refetch: refetchFloor, isLoading } = useGetFloor();
  const [tableData, setTableData] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setFloorId(data[0]?.uuid);
    }
  }, [data]);
  const fetchData = async () => {
    if (floorId) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/floor-table/?uuid=${floorId}`
      );

      setTableData(response?.data);
    }
  };
  useEffect(() => {
    if (tableData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loading, tableData]);
  useEffect(() => {
    fetchData();
  }, [floorId]);

  return (
    <>
      <TopSearch />
      <Box p={"1rem"} pt={"5rem"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          bg={"white"}
          py={"1rem"}
          borderBottom={" 1px solid #ECEDED"}
        >
          <Text
            color={"#000000"}
            fontSize={"27px"}
            fontWeight={"590"}
            ml={"3rem"}
          >
            TABLE LIST
          </Text>
          {isLoading ? (
            <Loading />
          ) : (
            <Box
              display={"flex"}
              mr={"2rem"}
              gap={"2rem"}
              borderRadius={"5px"}
              color={"#fff"}
            >
              {data?.map((item, index) => {
                return (
                  <Button
                    key={index}
                    size={"sm"}
                    bg={floorId === item?.uuid ? "#3AB689" : "#F8F8F8"}
                    borderRadius={"4px"}
                    p={"1rem"}
                    py={"1.3rem"}
                    minW={"7rem"}
                    color={floorId === item?.uuid ? "#fff" : "lightgray"}
                    textTransform={"capitalize"}
                    onClick={() => setFloorId(item?.uuid)}
                  >
                    {item?.name}
                  </Button>
                );
              })}
            </Box>
          )}
        </Box>
        {isLoading ? <Loading /> : <AvailaibleTable tableData={tableData} />}
      </Box>
    </>
  );
}
