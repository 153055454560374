import { Box, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import location from "../../../assets/img/location 1.png";
import water from "../../../assets/img/water.png";
import Favorites from "./Favorites";
import FoodMenu from "./FoodMenu";
import MostLiked from "./MostLiked";
import Breakfast from "./Breakfast";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

export default function Menu() {
  const [responseData, setResponseData] = useState([]);
  const currentURL = window.location.href;
  const parts = currentURL.split("/");
  const companyName = parts[parts.length - 1];
  const fetchData = async () => {
    const response = await authorizationAxios.get(
      `${BASE_URL}/api/menu/${companyName}`
    );
    setResponseData(response?.data);
    console.log("rr", response);
  };
  useEffect(() => {
    fetchData();
  }, [companyName]);
  const scrollRef = useRef(null);

  const handleScrollClick = (id) => {
    const element = document.getElementById(`div-${id}`);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Box className="menuitem-container" p={"1rem"}>
      <Text
        className="menu-header"
        display={"flex"}
        alignItems={"center"}
        gap={"0.5rem"}
        color={"#656565"}
        fontSize={"14px"}
        lineHeight={"32px"}
      >
        <img src={location} />
        Artlab Restaurant, Jhamiskhel, Lalitpur
      </Text>

      {/* <Box className="item-box">
        <Box mt="2rem" ml="0.5rem">
          {" "}
          <Box className="resturant-box">
            <Image src={water} className="menu-header-img" />
          </Box>
          <Text
            className="responsive-text"
            textAlign={"center"}
            mt="0.5rem"
            fontWeight={"600"}
          >
            Drinks
          </Text>
        </Box>
        <Box mt="2rem" ml="1rem">
          {" "}
          <Box className="resturant-box">
            <Image src={water} className="menu-header-img" />
          </Box>
          <Text
            className="responsive-text"
            textAlign={"center"}
            mt="0.5rem"
            fontWeight={"600"}
          >
            Drinks
          </Text>
        </Box>
        <Box mt="2rem" ml="1rem">
          {" "}
          <Box className="resturant-box">
            <Image src={water} className="menu-header-img" />
          </Box>
          <Text
            textAlign={"center"}
            className="responsive-text"
            mt="0.5rem"
            fontWeight={"600"}
          >
            Drinks
          </Text>
        </Box>
        <Box mt="2rem" ml="1rem">
          {" "}
          <Box className="resturant-box">
            <Image src={water} className="menu-header-img" />
          </Box>
          <Text
            className="responsive-text"
            textAlign={"center"}
            mt="0.5rem"
            fontWeight={"600"}
          >
            Drinks
          </Text>
        </Box>
      </Box> */}

   
      <Favorites responseData={responseData?.today_special} />
      <FoodMenu
        responseData={responseData?.category}
        handleScrollClick={handleScrollClick}
        scrollRef={scrollRef}
      />
      <MostLiked responseData={responseData?.most_liked} />
      <Breakfast
        responseData={responseData?.category_detail}
        handleScrollClick={handleScrollClick}
        scrollRef={scrollRef}
      />
    </Box>
  );
}
