import {
  Box,
  Grid,
  GridItem,
  Image,
  Input,
  Text,
  VStack,
  others,
  useToast,
  Checkbox,
  CheckboxGroup,
  useMediaQuery,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { PageTitle } from "components/Common/PageTitle";
import { SaveButton } from "components/Common/SaveButton";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetInventoryItemApi } from "../api/useGetInventoryItemApi";
import AuditTable from "./component/AuditTable";
import { usePostInventoryItem } from "./usePostInventoryItemDetail";
import MultipleImage from "views/Pages/Inventory/Add/component/MultipleImage";
import { useDeleteImageApi } from "../api/useDeleteImageApi";
import { BiTrash } from "react-icons/bi";
import ReceipeTable from "./component/ReceipeTable";
import useKitchenList from "views/Pages/Inventory/List/useKitchenList";
import { useGetRecipi } from "views/Pages/Inventory/api/useGetRecipi";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { PageSubTitle } from "components/Common/PageSubTitle";
import { Controller } from "react-hook-form";

export default function InventoryItemEdit() {
  const location = useLocation();
  const history = useHistory();
  const { data, refetch: refetchItemDetail } = useGetInventoryItemApi(
    location?.state?.sku
      ? location?.state?.sku
      : new URLSearchParams(location.search).get("sku")
  );
  console.log("oop", data?.data?.special);
  const {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
    isSuccess,
    file,
    handleUpload,
    handleClick,
    hiddenFileInput,
  } = usePostInventoryItem();
  const { deleteImageMutation } = useDeleteImageApi();
  const { isLoading, isError, error, isSuccess: success } = deleteImageMutation;
  const toast = useToast();
  const [isChecked, setIsChecked] = useState(false);
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  useEffect(() => {
    if (isSuccess) {
      history.push({
        pathname: "/admin/inventory-detail",
        search: "?id=" + data?.data.inventory_group,
        state: {
          id: location?.state?.id
            ? location?.state?.id
            : new URLSearchParams(location.search).get("id"),
        },
      });
    }
    if (success) {
      history.push({
        pathname: "/admin/inventory-detail",
        search: "?id=" + data?.data.inventory_group,
        state: {
          id: location?.state?.id
            ? location?.state?.id
            : new URLSearchParams(location.search).get("id"),
        },
      });
      toast({
        title: "Image Deleted",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [isSuccess, success]);

  useEffect(() => {
    refetchItemDetail();
  }, []);

  function deleteImage(uuid) {
    deleteImageMutation.mutate(uuid);
  }

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    setValue("special", event.target.checked);
  };

  console.log("val", isChecked);
  return (
    <>
      <TopSearch />
      <Box p={"1rem"} pt={"3rem"}>
        <Box
          display={"flex"}
          alignItems={"baseline"}
          gap={"4rem"}
          maxWidth={"100%"}
        >
          <Box flex="1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <PageSubTitle title="Edit Item" pl="0rem" p="0rem" pt="3rem" />
              {data &&
                data?.data?.sku ===
                  new URLSearchParams(location.search).get("sku") && (
                  <Box>
                    <input
                      {...register("uuid")}
                      type={"hidden"}
                      value={data?.data.uuid}
                    />
                    <LeftSideLabelInput
                      label="Item Name"
                      defaultValue={data?.data.name}
                      register={register}
                      registerName="name"
                    />
                    <LeftSideLabelInput
                      label="SKU"
                      defaultValue={data?.data.sku}
                      register={register}
                      registerName="sku"
                    />
                    <LeftSideLabelInput
                      label="UPC"
                      defaultValue={data?.data.upc}
                      register={register}
                      registerName="upc"
                    />
                    <LeftSideLabelInput
                      type="number"
                      label="Cost Price"
                      defaultValue={data?.data.cost_price}
                      register={register}
                      registerName="cost_price"
                    />
                    <LeftSideLabelInput
                      type="number"
                      label="Selling Price"
                      defaultValue={data?.data.selling_price}
                      register={register}
                      registerName="selling_price"
                    />
                    <Box
                      mt="1rem"
                      display={"flex"}
                      alignItems={"center"}
                      gap={"1rem"}
                    >
                      {data?.data && (
                        <Controller
                          name="special"
                          control={control}
                          defaultValue={data?.data?.special}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              defaultChecked={value}
                              onChange={(val) => onChange(val.target.checked)}
                              colorScheme="teal"
                            >
                              Today's Special
                            </Checkbox>
                          )}
                        />
                      )}
                    </Box>

                    <Box
                      mt="1rem"
                      display={"flex"}
                      alignItems={"center"}
                      gap={"1rem"}
                    >
                      {data?.data && (
                        <Controller
                          name="is_unlimited"
                          control={control}
                          defaultValue={data?.data?.is_unlimited}
                          render={({ field: { onChange, value} }) => (
                            <Checkbox
                              defaultChecked={value}
                              onChange={(val) => onChange(val.target.checked)}
                              colorScheme="teal"
                            >
                              Is Unlimited
                            </Checkbox>
                          )}
                        />
                      )}
                    </Box>

                    <Box height={"1rem"}></Box>
                    <MultipleImage
                      file={file}
                      handleUpload={handleUpload}
                      handleClick={handleClick}
                      hiddenFileInput={hiddenFileInput}
                    />
                    <Box display={"flex"} gap={"1rem"}>
                      {data?.data?.image?.map((item, index) => (
                        <Box>
                          <Image
                            objectFit={"cover"}
                            mt={"1rem"}
                            height={"6rem"}
                            width={"6rem"}
                            borderRadius={"1rem"}
                            border={"1px solid #3AB689"}
                            key={index}
                            src={item.image}
                          />
                          <Text
                            cursor={"pointer"}
                            onClick={() => deleteImage(item.uuid)}
                            color={"red.500"}
                            mt={"-1.5rem"}
                            ml={"0.5rem"}
                          >
                            <BiTrash />
                          </Text>{" "}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              <Box mt={"2rem"}>
                <SaveButton />
              </Box>
            </form>
          </Box>
          <Box flex="1">
            <Box>
              {localStorage.getItem("company_type") === "Restaurant" && (
                <PageTitle
                  title="Recipes"
                  style={{ paddingLeft: "0rem" }}
                  pl="0rem"
                  p="0rem"
                  pt="3rem"
                />
              )}
              <Box pr={"2rem"} mb="3rem">
                {localStorage.getItem("company_type") === "Restaurant" && (
                  <ReceipeTable uuid={data?.data?.uuid} data={data} />
                )}
              </Box>
              <Box display={breakPoint[0] ? "none" : "block"} p={"0 0.5rem"}>
                <PageTitle
                  mt="3rem"
                  title="Adjustment Audits"
                  style={{ paddingLeft: "0rem" }}
                />

                <Box pr={"2rem"}>
                  {data && <AuditTable uuid={data?.data.uuid} />}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex="1" display={breakPoint[0] ? "block" : "none"} p={"0 0.5rem"}>
          <Box>
            <Box>
              <PageTitle
                pl="0rem"
                p="0rem"
                pt="3rem"
                title="Adjustment Audits"
              />

              <Box pr={"2rem"}>
                {data && <AuditTable uuid={data?.data.uuid} />}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
