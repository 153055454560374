import {
  Portal,
  Box,
  useColorMode,
  Stack,
  useDisclosure,
  useColorModeValue,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import "assets/css/plugin-styles.css";
import Configurator from "components/Configurator/Configurator";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
import Footer from "components/Footer/Footer.js";
import Logo from "../assets/img/logo.png";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "routes.js";
import Profile from "views/Pages/UserProfile/View/Profile.jsx";

import ProfileDetails from "views/Pages/UserProfile/Edit/ProfileDetails";
import EditPeak from "views/Pages/ExpeditionAndPackage/Peak/Edit/EditPeak";
import Peak from "views/Pages/ExpeditionAndPackage/Peak/View/Peak";
import AddPeak from "views/Pages/ExpeditionAndPackage/Peak/Add/AddPeak";
import AddExpedition from "views/Pages/ExpeditionAndPackage/Expedition/Add/AddExpedition";
import AddPackage from "views/Pages/ExpeditionAndPackage/Package/Add/AddPackage";
import AllBooking from "views/Pages/Booking/AllBooking";
import InventoryDetail from "views/Pages/InventoryDetail/InventoryDetail";
import InventoryDetailEdit from "views/Pages/InventoryDetail/Edit/InventoryDetailEdit";
import InventoryItemEdit from "views/Pages/InventoryDetail/Edit/InventoryItemEdit";
import TableList from "views/Pages/TabeList/List/TableList";
import Brands from "views/Pages/Settings/Brands/list/Brands";
import Categories from "views/Pages/Settings/Categories/list/Categories";
import Floor from "views/Pages/Settings/Floor/list/Floor";
import Manufacturer from "views/Pages/Settings/Manufacturer/list/Manufacturer";
import Order from "views/Pages/Order/List/Order";
import MenuList from "views/Pages/Menu/List/Edit/MenuList";
import Menus from "views/Pages/Menu/List/MenuList";
import KitchenDetail from "views/Pages/InventoryDetail/Edit/component/KitchenDetail";
import OrderRetail from "views/Pages/Order/List/Retailer/OrderRetai";
import RetaiIs from "views/Pages/Menu/List/Retailer/Edit/RetaiItem";
import { SalesReport } from "views/Pages/SalesReport/list/SalesReport";
import Menu from "views/PublicPages/Menu/Menu";

// Custom Chakra theme
export default function Dashboard(props) {
  const breakPoint = useMediaQuery("(max-width: 1200px)");
  const history = useHistory();
  console.log(localStorage.getItem("company_type"));
  if (+localStorage.getItem("unAuthorized") === 403) {
    // history.push("/signin");
  }

  
  // console.log(localStorage);



  const { ...rest } = props;
  // states and functions
  const [fixed, setFixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(275);
  // ref for main panel div
  const mainPanel = React.createRef();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  let bgBoxHeight = "40vh";
  let bgBoxColor = useColorModeValue("blue.500", "navy.900");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";
  // Chakra Color Mode
  return (
    <Box >
      <SidebarContext.Provider 
        value={{
          sidebarWidth,
          setSidebarWidth,
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          minH={bgBoxHeight}
          h="100% !important"
          w="100%"
          position="absolute"
          // bg={bgBoxColor}
          top="0"
        />
        {!breakPoint[0] && (
          <Sidebar 
            routes={routes}
            logo={
              <>
                <Image src={Logo} width={"40px"} />
              </>
            }
            display="none"
            {...rest}
          />
        )}
        
        

        <MainPanel
          w={{
            base: "100%",
            // xl: `calc(100% - ${sidebarWidth}px)`,
            xl: `calc(100% - 70px)`,
          }}
        >
          <Portal>
            <Box>
              <AdminNavbar
                onOpen={onOpen}
                logoText={"Aalaya"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <PanelContent>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes)}
                  <Route
                    path={`/admin/menu-item/`}
                    component={Menus}
                    exact={true}
                  />
                  <Route
                    path={`/admin/order/:uuid/`}
                    component={MenuList}
                    exact={true}
                  />
                  <Route
                    path={`/admin/profile`}
                    component={Profile}
                    exact={true}
                  />
                  <Route
                    path={`/admin/order-item/:uuid`}
                    component={Order}
                    exact={true}
                  />
                  <Route
                    path={`/admin/edit/:uuid/`}
                    component={RetaiIs}
                    exact={true}
                  />
                  <Route
                    path={`/admin/payment/:uuid/`}
                    component={OrderRetail}
                    exact={true}
                  />
                  <Route
                    path={`/admin/profile-update`}
                    component={ProfileDetails}
                    exact={true}
                  />
                  <Route
                    path={`/admin/peak-view`}
                    component={Peak}
                    exact={true}
                  />
                  <Route
                    path={`/admin/peak-add-new`}
                    component={AddPeak}
                    exact={true}
                  />
                  <Route
                    path={`/admin/peak-update`}
                    component={EditPeak}
                    exact={true}
                  />
                  <Route
                    path={`/admin/expedition-add-new`}
                    component={AddExpedition}
                    exact={true}
                  />
                  <Route
                    path={`/admin/package-add-new`}
                    component={AddPackage}
                    exact={true}
                  />
                  <Route
                    path={`/admin/booking-view`}
                    component={AllBooking}
                    exact={true}
                  />
                  <Route
                    path={`/admin/inventory-detail`}
                    component={InventoryDetail}
                    exact={true}
                  />
                  <Route
                    path={`/admin/inventory-detail-edit`}
                    component={InventoryDetailEdit}
                    exact={true}
                  />
                  <Route
                    path={`/admin/kitchen-detail/:uuid`}
                    component={KitchenDetail}
                    exact={true}
                  />
                  <Route
                    path={`/admin/inventory-item-edit`}
                    component={InventoryItemEdit}
                    exact={true}
                  />
                  <Route
                    path={`/admin/brand-list`}
                    component={Brands}
                    exact={true}
                  />

                  <Route
                    path={`/admin/category-list`}
                    component={Categories}
                    exact={true}
                  />
                  <Route
                    path={`/admin/floor-table-list`}
                    component={Floor}
                    exact={true}
                  />
                  <Route
                    path={`/admin/manufacturer-list`}
                    component={Manufacturer}
                    c
                    exact={true}
                  />
                  <Route
                    path={`/admin/sales-report`}
                    component={SalesReport}
                    exact={true}
                  />
                  {localStorage.getItem("company_type") === "Retailer" && (
                    <Redirect from="/admin" to="/admin/retail-item" />
                  )}
                  {localStorage.getItem("company_type") === "Restaurant" && (
                    <Redirect from="/admin" to="/admin/table-list" />
                  )}
                </Switch>
              </PanelContainer>
            </PanelContent>
          ) : null}
          <Box>{/* <Footer /> */}</Box>
          <Portal>
            <Box>{/* <FixedPlugin fixed={fixed} onOpen={onOpen} /> */}</Box>
          </Portal>
          {/* <Configurator
            secondary={getActiveNavbar(routes)}
            isOpen={isOpen}
            onClose={onClose}
            isChecked={fixed}
            onSwitch={(value) => {
              setFixed(value);
            }}
          /> */}
        </MainPanel>
      </SidebarContext.Provider>
    </Box>
  );
}
