import { Box } from '@chakra-ui/react'
import axios from 'axios'
import { LeftSideLabelInput } from 'components/Common/LeftSideLabelInput'
import { PageTitle } from 'components/Common/PageTitle'
import { SaveButton } from 'components/Common/SaveButton'
import { useGetInventoryDetailApi } from '../api/useGetInventoryDetailApi'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { usePostInventoryDetail } from './usePostInventoryDetail'
import LeftSideLabelTextArea from 'components/Common/LeftSideLabelTextArea'
import TopSearch from 'components/Navbars/TopSearchBar/TopSearch'
import { PageSubTitle } from 'components/Common/PageSubTitle'

export default function InventoryDetailEdit() {

    const location = useLocation();
    const history = useHistory();

    const { data } = useGetInventoryDetailApi(location?.state?.id ? location?.state?.id : new URLSearchParams(location.search).get("id"));

    const {
        handleSubmit,
        register,
        onSubmit,
        setValue,
        getValues,
        watch,
        unregister,
        control,
        isSuccess
    } = usePostInventoryDetail();


    useEffect(() => {
        if (isSuccess) {
            // console.log('successfully updated')
            history.push({
                pathname: '/admin/inventory-detail',
                search: '?id=' + (location?.state?.id ? location?.state?.id : new URLSearchParams(location.search).get("id")),
                state: { id: (location?.state?.id ? location?.state?.id : new URLSearchParams(location.search).get("id")) }
            })
        }
    }, [isSuccess])

    return (
        <>
            <TopSearch />
            <Box p={'1rem'} pt={'5rem'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <PageSubTitle title='Edit Group' />
                    {
                        data &&
                        <Box >
                            <input {...register('uuid')} type={'hidden'} value={location?.state?.id ? location?.state?.id : new URLSearchParams(location.search).get("id")} />

                            <LeftSideLabelInput label='Name' defaultValue={data ? data['group-details'].name : ''} register={register} registerName='name' />

                            <LeftSideLabelInput label='Unit' defaultValue={data ? data['group-details'].unit:''} register={register} registerName='unit' />
                            {/* <LeftSideLabelInput label='Brand' defaultValue={data ? data['group-details'].brand : ''} register={register} registerName='brand' />
                            <LeftSideLabelInput label='Manufacturer' defaultValue={data ? data['group-details'].manufactured_by : ''} register={register} registerName='manufactured_by' /> */}

                            <LeftSideLabelTextArea label='Description' defaultValue={data ? data['group-details'].description : ''} register={register} registerName='description' />

                        </Box>
                    }
                    <Box mt={'2rem'}>
                        <SaveButton />
                    </Box>
                </form>
            </Box>
        </>
    )
}
