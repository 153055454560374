import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import Moment from "react-moment";
import { Loading } from "components/Common/Loading";

import { NoDataMsg } from "components/Common/NoDataMsg";
import { useGetReportApi } from "../api/useGetReportApi";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useNotify } from "common hooks/useNotify";
import axios from "axios";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { PageText } from "components/Common/PageText";
import { useGetBrandList } from "common apis/useGetBrandList";
import { useGetInvoiceReportApi } from "./useGetInvoiceReportApi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import PaginationButton from "components/Common/PaginationButton copy";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ReactToPrint from "react-to-print";

const InvoiceReport = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify } = useNotify();
  const { errorNotify } = useNotify();
  const [next, setNext] = useState(1);
  const [voidsStatus, setVoidStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const componentRef = useRef();
  const { data, refetch, isLoading } = useGetInvoiceReportApi(
    document.getElementById("startdate")?.value,
    document.getElementById("enddate")?.value,
    next
  );

  const handleClick = async () => {
    const response = await authorizationAxios.get(
      `${BASE_URL}/api/retailer/report/?start_date=${
        document.getElementById("startdate")?.value
      }&end_date=${document.getElementById("enddate")?.value}&export=true`
    );
    if (response?.status === 200) {
      successNotify(response?.data?.message);
    }
  };

  const handleOpenModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    setModalData(null);
  };
  console.log("check", modalData);
  const handleVoid = async () => {
    const invoice = {
      uuid: modalData?.uuid,
    };
    const response = await authorizationAxios.patch(
      `${BASE_URL}/api/invoice/void/`,
      invoice
    );

    if (response?.status === 200) {
      setVoidStatus(true);
      successNotify(response?.data?.message);
      setIsOpen(false);
    } else {
      errorNotify("Something went wrong");
    }
  };
  useEffect(() => {
    refetch();
  }, [voidsStatus]);
  return (
    <Box bg="white" minH="100vh">
      <InvoiceModal
        data={data}
        isOpen={isOpen}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        modalData={modalData}
        handleVoid={handleVoid}
      />
      <Box
        display={"flex"}
        p={"1rem"}
        borderBottom={"1px solid lightgray"}
        alignItems={"center"}
      >
        {/* {localStorage.getItem("company_type") == "Restaurant" && (
          <Text width={"85%"}>SALES REPORT</Text>
        )} */}

        <Box display={"flex"} alignItems={"center"}>
          <Text w={"6rem"} ml={"1rem"}>
            Start Date :{" "}
          </Text>
          <Input width={"10rem"} type="date" id="startdate" />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Text w={"5rem"} ml={"1rem"}>
            End Date :{" "}
          </Text>
          <Input width={"10rem"} type="date" id="enddate" />
        </Box>
        <Button
          width={"5rem"}
          onClick={() => refetch()}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
        >
          Submit
        </Button>

        {/* <Button
          width={"5rem"}
          onClick={handleClick}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
        >
          Export
        </Button> */}
      </Box>

      <Table variant="striped">
        <Tr>
          <Th style={globalStyles.customStyle.tableHeading}> Order Id</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Order Date</Th>
          <Th style={globalStyles.customStyle.tableHeading}> Order Amount </Th>

          <Th style={globalStyles.customStyle.tableHeading}> Payment Amount</Th>
          {/* <Th style={globalStyles.customStyle.tableHeading}> Invoice Amount</Th> */}
          <Th style={globalStyles.customStyle.tableHeading}>Payment Type</Th>
        </Tr>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Tbody>
              {data?.data?.map((item, index) => (
                <Tr
                  cursor={"pointer"}
                  key={index}
                  onClick={() => handleOpenModal(item)}
                >
                  <Td display={"flex"} gap={"3px"}>
                    <Text>{item?.invoiceNumber}</Text>
                    {item?.is_void && (
                      <Badge variant="solid" colorScheme="red">
                        VOID
                      </Badge>
                    )}
                  </Td>
                  <Td>
                    <Moment format="DD-MM-YYYY">{item?.invoicedate}</Moment>
                  </Td>
                  <Td>{item?.total_amount}</Td>
                  {item?.payment_amount ? (
                    <Td>
                      {item?.currency}
                      {item?.payment_amount}
                    </Td>
                  ) : (
                    <Td>-</Td>
                  )}

                  <Td>
                    {item?.payment_type?.map((item, index) => (
                      <spa key="index">{item}, </spa>
                    ))}
                  </Td>

                  {/* <Td>
                {item?.currency}
                {item?.billAmount}
              </Td> */}
                </Tr>
              ))}
              {data?.data === undefined && (
                <Tr cursor={"pointer"}>
                  <Td></Td>
                  <Td></Td>
                  <Td>
                    <NoDataMsg msg="No Data Found" pos="relative" />
                  </Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
            <PaginationButton
              isPrevious={data?.previous}
              isNext={data?.next}
              next={next}
              setNext={setNext}
            />
          </>
        )}
      </Table>
    </Box>
  );
};
export default InvoiceReport;

function InvoiceModal({
  isOpen,

  modalData,

  handleCloseModal,

  handleVoid,
}) {
  const componentRef = useRef();
  console.log("xx", modalData);

  const { isOpen: voidIsOpen, onOpen:voidOnOpen, onClose:voidOnClose } = useDisclosure();
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ComponentToPrint data={modalData} ref={componentRef} />
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => <Button colorScheme="green">Print Order</Button>}
              content={() => componentRef.current}
            />
            {modalData?.is_void === false && (
              // <Button ml="1rem" colorScheme="red" onClick={handleVoid}>
               <Button ml="1rem" colorScheme="red" onClick={voidOnOpen}>
                Void Order
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={voidIsOpen} onClose={voidOnClose} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Void Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
              Do you really want to void Invoice?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={()=>{handleVoid(); voidOnClose();}}  >
              Yes
            </Button>
            <Button variant="ghost" onClick={voidOnClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export const ComponentToPrint = React.forwardRef((props, ref) => {
  console.log("pp", props);
  return (
    <div ref={ref}>
      <ModalHeader textAlign={"center"}>Estimate</ModalHeader>
      <Text textAlign={"center"} size="lg" mt={"-1rem"}>
        Order #:{props.data.invoiceNumber}
      </Text>
      {/* <Text textAlign={"center"} size="lg" mb={"1rem"}>
        Order Date#:{new Date()}
      </Text> */}
      <table>
        <tr
          style={{
            borderBottom: "1px dotted gray",
            textAlign: "left",
          }}
        >
          <th style={{ width: "150px", fontSize: "14px" }}>Descriptions</th>
          <th style={{ width: "50px", fontSize: "14px" }}>Qty</th>
          <th style={{ width: "30px", fontSize: "14px" }}>Price</th>
          <th style={{ width: "100px", fontSize: "14px" }}>Subtotal</th>
          <th style={{ width: "100px", fontSize: "14px" }}>Dis</th>

          <th style={{ width: "100px", fontSize: "14px" }}>Total</th>
        </tr>
        {props?.data?.items?.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ width: "150px", fontSize: "12px" }}>
                {item?.product__name}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {item?.quantity}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {item?.complimentry ? <p>comp:</p> : <>{item?.unitprice}</>}
              </td>

              <td
                style={{
                  width: "100px",
                  fontSize: "12px",
                  marginRight: "10px",
                }}
              >
                {item?.complimentry ? <p>0</p> : <> {item?.billAmount}</>}
              </td>
              <td style={{ width: "30px", fontSize: "12px" }}>
                {item?.discount}%
              </td>
              <td style={{ width: "30px", fontSize: "12px" }}>
                {" "}
                {item?.complimentry ? (
                  <p>Rs.0</p>
                ) : (
                  <>
                    Rs.
                    {item?.billAmount -
                      (item?.discount / 100) * item?.billAmount}
                  </>
                )}
              </td>
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Subtotal</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "left", fontSize: "12px" }}>
            Rs.
            {props.data.sub_total}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Adjustment</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "left", fontSize: "12px" }}>
            Rs. {props.data.adjustment_amount ?? 0}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Discount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "left", fontSize: "12px" }}>
            Rs.
            {props.data.discount_amount}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Service charge</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "left", fontSize: "12px" }}>
            Rs.
            {props.data.service_charge}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Due Amount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "left", fontSize: "12px" }}>
            Rs.{props.data.dueAmount ?? 0}
          </th>
        </tr>
        <tr
          style={{
            borderTop: "1px dotted gray",
            textAlign: "left",
            fontSize: "12px",
          }}
        >
          <th style={{ width: "150px", fontSize: "12px" }}>Total</th>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "left", fontSize: "12px" }}>
            Rs.
            {props.data.total_amount}
          </th>
        </tr>
      </table>
    </div>
  );
});
