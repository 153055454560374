import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsPeople } from "react-icons/bs";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { usePostAmount } from "./api/usePostAmount";
import { FaMoneyBillWave, FaMoneyCheckAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { globalStyles } from "theme/styles";
export default function OrderPayment({
  menuData,
  isOpen,
  onOpen,
  onClose,
  isCancelOpen,
  onCancelOpen,
  onCancelClose,
  cancelOrder,
}) {
  const [totalAmount, setTotalAmount] = useState("");
  const [sub, setSub] = useState(0);
  const [discountAmount, setDiscountAmount] = useState("");
  const [serviceAmount, setServiceAmount] = useState("");

  const [adjustment, setAdjustment] = useState("");
  const [due, setDue] = useState(totalAmount);
  const [activeTab, setActiveTab] = useState(0);
  const { postAmountMutation } = usePostAmount();
  const [cashReceived, setCashReceived] = useState("");
  const toast = useToast();
  const { isLoading, isError, error, isSuccess } = postAmountMutation;
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [payValue, setPayValue] = useState("");
  const [dueAmount, setDueAmount] = useState(0);
  const [cashState, setCashState] = useState(0);
  const [cardState, setCardState] = useState(0);
  const [esewaState, setEsewaState] = useState(0);
  const [guest, setGuest] = useState("");
  const history = useHistory();
  const [response, setResponse] = useState([]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (activeTab === 1) {
      setPayValue("CARD");
    }
    if (activeTab === 2) {
      setPayValue("ESEWA");
    }
    if (activeTab === 0) {
      setPayValue("CASH");
    }
  }, [activeTab, payValue]);
  const fetchData = async () => {
    if (menuData) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/order/?invoice_number=${menuData}`
      );

      setTotalAmount(response?.data[0]?.total_amount);
      setDiscountAmount(response?.data[0]?.discount);
      setServiceAmount(response?.data[0]?.tax);
      setDue(response?.data[0]?.dueAmount);
      setDueAmount(response?.data[0]?.dueAmount);
      setSub(response?.data[0]?.sub_total);
      setAdjustment(response?.data[0]?.adjustment_amount);
      setGuest(response?.data[0]?.guest);
      setResponse(response?.data[0]);
    }
  };
  const priceAfterDisocunt = sub - (discountAmount / 100) * sub;
  const serviceCharge =
    (serviceAmount / 100) * (priceAfterDisocunt + adjustment);

  useEffect(() => {
    fetchData();
  }, [menuData]);
  
  const payNow = (payValue) => {
    const finalData = {
      invoice_number: menuData,
      payment_type: [
        {
          CASH: parseFloat(
            document.getElementById("cash")?.value
              ? document.getElementById("cash")?.value
              : "0.0"
          ),

          BANK: parseFloat(
            document.getElementById("esewa")?.value
              ? document.getElementById("esewa")?.value
              : "0.0"
          ),

          CARD: parseFloat(
            document.getElementById("card")?.value
              ? document.getElementById("card")?.value
              : "0.0"
          ),
        },
      ],
    };

    postAmountMutation.mutate(finalData);
  };
  function onChange() {
    setDue(
      totalAmount -
        parseFloat(
          document.getElementById("cash")?.value
            ? document.getElementById("cash")?.value
            : "0.0"
        ) -
        parseFloat(
          document.getElementById("card")?.value
            ? document.getElementById("card")?.value
            : "0.0"
        ) -
        parseFloat(
          document.getElementById("esewa")?.value
            ? document.getElementById("esewa")?.value
            : "0.0"
        )
    );
  }
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: " Payment done successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      localStorage.removeItem("customer_name");
      localStorage.removeItem("customer_number");
      localStorage.removeItem("customer_uuid");
      history.push("/admin/table-list");
      // onCloseEdit();
    }
    if (isError) {
      toast({
        title: postAmountMutation?.error?.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [postAmountMutation.isSuccess, postAmountMutation.isError]);
  return (
    <Box
      height={"100%"}
      w={breakPoint[0] ? "" : "25rem"}
      mt={breakPoint[0] ? "0px" : "70px"}
    >
      <Box borderBottom={"1px dashed #A9A9A9"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={breakPoint[0] ? "0rem" : "1rem"}
          pb={"0rem"}
          alignItems={"center"}
        >
          <Text
            color={"#000000"}
            fontSize={breakPoint[0] ? "20px" : "23px"}
            fontWeight={"600"}
          >
            PAYABLE AMOUNT
          </Text>
          {localStorage.getItem("employee_type") !== "Employee" && (
            <Text
              color="#3AB689"
              fontSize={"14px"}
              fontWeight={"700"}
              cursor={"pointer"}
              onClick={onOpen}
            >
              EDIT
            </Text>
          )}
        </Box>
        <Box className="payment_wrapper1">
          <Text color={"#EC5A5A"} fontSize={"20px"} fontWeight={"700"}>
            Rs.{totalAmount}
          </Text>

          <Text
            marginLeft={"1rem"}
            display={"flex"}
            alignItems={"baseline"}
            gap="5px"
            mt="5px"
            color={"#2C3832"}
            fontSize={"12px"}
            fontWeight={"600"}
          >
            <BsPeople /> <span style={{ marginLeft: "3px" }}> GUEST</span>:
            <span
              style={{
                color: "#D89F65",
                marginLeft: breakPoint[0] ? "2px" : "",
                fontSize: "14px",
              }}
            >
              {guest}
            </span>
          </Text>
          {response?.customer_name ? (
            <Text
              style={{ cursor: "pointer" }}
              color={globalStyles.customStyle.fontColor.color}
              fontSize={"15px"}
            >
              {response.customer_name}
            </Text>
          ) : (
            <>
              <Text
                style={{ cursor: "pointer" }}
                color={globalStyles.customStyle.fontColor.color}
                fontSize={"15px"}
              >
                {response.customer_number}
              </Text>
            </>
          )}
        </Box>
      </Box>
      <Payment
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
        activeTab={activeTab}
        due={due}
        priceAfterDisocunt={priceAfterDisocunt}
        setDue={setDue}
        serviceCharge={serviceCharge}
        discountAmount={discountAmount}
        serviceAmount={serviceAmount}
        setActiveTab={setActiveTab}
        handleTabChange={handleTabChange}
        payNow={payNow}
        cashReceived={cashReceived}
        setCashReceived={setCashReceived}
        adjustment={adjustment}
        onChange={onChange}
        response={response}
        cashState={cashState}
        setCashState={setCashState}
        cardState={cardState}
        setCardState={setCardState}
        esewaState={esewaState}
        setEsewaState={setEsewaState}
        sub={sub}
        breakPoint={breakPoint}
        isCancelOpen={isCancelOpen}
        onCancelOpen={onCancelOpen}
        onCancelClose={onCancelClose}
        cancelOrder={cancelOrder}
      />
    </Box>
  );
}

function Payment({
  breakPoint,
  payNow,
  totalAmount,
  due,
  discountAmount,
  serviceAmount,
  adjustment,
  onChange,
  serviceCharge,
  priceAfterDisocunt,
  response,
  cashState,
  cardState,
  esewaState,
  setCardState,
  setCashState,
  setEsewaState,
  sub,
  isCancelOpen,
  onCancelOpen,
  onCancelClose,
  cancelOrder,
}) {
  const [cashShow, setCashShow] = useState(true);
  const [cardShow, setCardShow] = useState(false);
  const [esewaShow, setEsewaShow] = useState(false);
  const componentRef = useRef();
  const {
    isOpen: isOpened,
    onOpen: onOpened,
    onClose: onClosed,
  } = useDisclosure();

  useEffect(() => {
    response?.payment_detail?.map((item) => {
      if (item?.type === "CASH") {
        if (item?.price && item?.price != 0) {
          // setCashState(item?.price);
          document.getElementById("cash").value = item?.price ?? 0;
          setCashShow(true);
        }
      }

      if (item?.type === "CARD") {
        if (item?.price && item?.price != 0) {
          // document.getElementById("card").value = item?.price ?? 0;
          setCardState(item?.price);
          setCardShow(true);
        }
      }
      if (item?.type === "BANK") {
        if (item?.price && item?.price != 0) {
          setEsewaState(item?.price ?? 0);
          // document.getElementById("esewa").value = item?.price ?? 0;
          setEsewaShow(true);
        }
      }
    });
  }, [response, cashState, cardState, esewaState]);
  return (
    <Box>
      <Box>
        <Box display={"flex"} p={"0.5rem"} gap={"0.5rem"}>
          <Box
            width={"100%"}
            cursor={"pointer"}
            onClick={() => setCashShow(!cashShow)}
            bg={cashShow ? "#EAF0F0" : "#FAFAFA"}
            textAlign={"-webkit-center"}
            p={"0.5rem"}
          >
            <FaMoneyBillWave
              size={"1.5rem"}
              color={cashShow ? "#000" : "#DFDEDE"}
            />
            <Text fontWeight={"600"} color={cashShow ? "#000" : "#DFDEDE"}>
              CASH
            </Text>
          </Box>
          <Box
            width={"100%"}
            cursor={"pointer"}
            onClick={() => setCardShow(!cardShow)}
            bg={cardShow ? "#EAF0F0" : "#FAFAFA"}
            textAlign={"-webkit-center"}
            p={"0.5rem"}
          >
            <FaMoneyCheckAlt
              size={"1.5rem"}
              color={cardShow ? "#000" : "#DFDEDE"}
            />
            <Text fontWeight={"600"} color={cardShow ? "#000" : "#DFDEDE"}>
              CARD
            </Text>
          </Box>
          <Box
            width={"100%"}
            cursor={"pointer"}
            onClick={() => setEsewaShow(!esewaShow)}
            bg={esewaShow ? "#EAF0F0" : "#FAFAFA"}
            textAlign={"-webkit-center"}
            p={"0.5rem"}
          >
            <FaMoneyBillWave
              size={"1.5rem"}
              color={esewaShow ? "#000" : "#DFDEDE"}
            />
            <Text fontWeight={"600"} color={esewaShow ? "#000" : "#DFDEDE"}>
              BANK
            </Text>
          </Box>
        </Box>
        {cashShow && (
          <Box
            mt={"0.5rem"}
            bg="#ECF3F5"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"1rem"}
          >
            <Text fontSize={"16px"} fontWeight={"600"}>
              ADD CASH RECEIVED
            </Text>
            <HStack>
              <Text fontSize={"20px"} fontWeight={"600"}>
                {response?.currency}
              </Text>

              <Input
                fontSize={"20px"}
                fontWeight={"600"}
                border={"none"}
                width={"8rem"}
                borderBottom={"1px solid #1D2521"}
                defaultValue={0}
                type="number"
                textAlign={"right"}
                id="cash"
                onChange={() => onChange()}
              />
            </HStack>
          </Box>
        )}

        {cardShow && (
          <Box
            mt={"0.5rem"}
            bg="#ECF3F5"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"1rem"}
          >
            <Text fontSize={"16px"} fontWeight={"600"}>
              ADD CARD RECEIVED
            </Text>
            <HStack>
              <Text fontSize={"20px"} fontWeight={"600"}>
                {response?.currency}
              </Text>

              <Input
                fontSize={"20px"}
                fontWeight={"600"}
                border={"none"}
                width={"8rem"}
                borderBottom={"1px solid #1D2521"}
                defaultValue={cardState}
                type="number"
                textAlign={"right"}
                id="card"
                onChange={() => onChange()}
              />
            </HStack>
          </Box>
        )}

        {esewaShow && (
          <Box
            mt={"0.5rem"}
            bg="#ECF3F5"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"1rem"}
          >
            <Text fontSize={"16px"} fontWeight={"600"}>
              ADD BANK RECEIVED
            </Text>
            <HStack>
              <Text fontSize={"20px"} fontWeight={"600"}>
                {response?.currency}
              </Text>

              <Input
                fontSize={"20px"}
                fontWeight={"600"}
                border={"none"}
                width={"8rem"}
                borderBottom={"1px solid #1D2521"}
                defaultValue={esewaState}
                type="number"
                textAlign={"right"}
                id="esewa"
                onChange={() => onChange()}
              />
            </HStack>
          </Box>
        )}
      </Box>

      <Box>
        {/* SUBTOTAL CALCULATION */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"1rem"}
          borderBottom={"1px dashed #A9A9A9"}
        >
          <Box>
            <Text fontSize={"16px"} fontWeight={"600"}>
              SUBTOTAL
            </Text>
          </Box>
          <Box>
            <Text fontSize={"16px"} fontWeight={"600"}>
              Rs. {sub}
            </Text>
          </Box>
        </Box>

        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            DISCOUNT
          </Text>
          {discountAmount ? (
            <Text fontSize="16px" fontWeight="700">
              Rs. {(discountAmount / 100) * sub}
            </Text>
          ) : (
            <Text fontSize="16px" fontWeight="700">
              Rs. 0
            </Text>
          )}
        </Box>

        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            ADJUSTMENT
          </Text>
          <Text fontSize="16px" fontWeight="700">
            Rs. {adjustment}
          </Text>
        </Box>

        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            SERVICE CHARGE
          </Text>
          <Text fontSize="16px" fontWeight="700">
            {serviceAmount ? (
              <Text fontSize="16px" fontWeight="700">
                Rs.{parseFloat(serviceCharge)}
              </Text>
            ) : (
              <Text fontSize="16px" fontWeight="700">
                Rs. 0
              </Text>
            )}
          </Text>
        </Box>
        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            TOTAL
          </Text>
          <Text fontSize="16px" fontWeight="700">
            Rs. {totalAmount}
          </Text>
        </Box>
        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            DUE
          </Text>
          <Text fontSize="16px" fontWeight="700">
            Rs. {due ? parseFloat(due) : 0}
          </Text>
        </Box>
        <Box p={"0.5rem"}>
          <Button
            background={"none"}
            fontSize={"sm"}
            p={"0px"}
            onClick={onOpened}
          >
            Print Order
          </Button>
          <Box className="button_wrapper">
            <Button
              disabled={
                localStorage.getItem("employee_type") !== "Employee"
                  ? false
                  : true
              }
              w={breakPoint[0] ? "50%" : "100%"}
              _hover={{ backgroundColor: "buttongreen.500", color: "white" }}
              variant="secondary"
              mt="1rem"
              color={"#ffff"}
              height={"70px"}
              borderRadius={breakPoint[0] ? "0px" : "8px"}
              bg={"buttongreen.400"}
              onClick={() =>
                payNow([
                  {
                    CASH: parseFloat(
                      document.getElementById("cash")?.value
                        ? document.getElementById("cash")?.value
                        : "0.0"
                    ),
                    CARD: parseFloat(
                      document.getElementById("card")?.value
                        ? document.getElementById("card")?.value
                        : "0.0"
                    ),

                    BANK: parseFloat(
                      document.getElementById("esewa")?.value
                        ? document.getElementById("esewa")?.value
                        : "0.0"
                    ),
                  },
                ])
              }
            >
              PAY NOW
            </Button>
            {breakPoint[0] && (
              <Button
                _hover={{ backgroundColor: "buttonred.500", color: "black" }}
                width={"50%"}
                height={"72px"}
                borderRadius={breakPoint[0] ? "0px" : "8px"}
                bg="buttonred.400"
                variant="secondary"
                color={"#fff"}
                marginLeft={"1rem"}
                onClick={onCancelOpen}
              >
                CANCEL ORDER
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {/* <Modal isOpen={isOpened} onClose={onClosed}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ComponentToPrint
              ref={componentRef}
              data={response}
              adjustment={adjustment}
              serviceCharge={serviceAmount ? parseFloat(serviceCharge) : 0}
              discountAmount={discountAmount ? (discountAmount / 100) * sub : 0}
              total={totalAmount}
            />
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => <Button variant="ghost">Print Order</Button>}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Box>
  );
}

export const ComponentToPrint = React.forwardRef((props, ref) => {
  console.log(props);
  return (
    <div ref={ref}>
      <ModalHeader textAlign={"center"}>Order Details</ModalHeader>
      <Text textAlign={"center"} size="lg" mt={"-1rem"}>
        Order #:{props?.data?.invoiceNumber}
      </Text>
      {/* <Text textAlign={'center'} size="lg" mb={'1rem'}>Order Date#:{new Date()}</Text> */}
      <table>
        <tr style={{ borderBottom: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px", fontSize: "12px" }}>Item</th>
          <th style={{ width: "50px", fontSize: "12px" }}>Price</th>
          <th style={{ width: "30px", fontSize: "12px" }}>Qty</th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            SUBTOTAL
          </th>
        </tr>
        {props?.data?.invoice_item?.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ width: "150px", fontSize: "12px" }}>{item?.name}</td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {item?.selling_price}
              </td>
              <td style={{ width: "30px", fontSize: "12px" }}>
                {item?.qty ?? item?.quantity}
              </td>
              {item?.quantity && (
                <td
                  style={{
                    width: "100px",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                >
                  {item?.selling_price * item?.quantity}{" "}
                </td>
              )}
              {item?.qty && (
                <td
                  style={{
                    width: "100px",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                >
                  Nrs.
                  {item?.selling_price * item?.qty}{" "}
                </td>
              )}
            </tr>
          );
        })}

        <tr style={{ textAlign: "left", borderTop: "1px dotted gray" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Discount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right" }}>
            Nrs.{props?.discountAmount}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Adjustment</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            Nrs.
            {props?.adjustment}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Service Charge</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right" }}>
            Nrs. {props?.serviceCharge}
          </th>
        </tr>
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px", fontSize: "12px" }}>Total</th>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            Nrs.{props?.total}
          </th>
        </tr>
      </table>
    </div>
  );
});
