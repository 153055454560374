import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  toast,
} from "@chakra-ui/react";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
// import { useGetFloor } from "./api/useGetFloor";
import { useNotify } from "common hooks/useNotify";
import { Loading } from "components/Common/Loading";
import { useEditPayment } from "../api/useEditPayment";
import { useEditRetailerPayment } from "./api/useEditRetailerPayment";
import moment from "moment/moment";

export default function EditRetailerPayment({
  invId,
  isOpen,
  onClose,
  onOpen,
  menuData,
  tableInfo,
}) {
  const currencyList = [
    { value: "RS", label: "RS" },
    { value: "$", label: "$" },
    { value: "€", label: "€" },
  ];
  const {
    register,
    control,
    onSubmit,
    setValue,
    getValues,
    isSuccess,
    isError,
    isLoading,
    error,
    data: editData,
  } = useEditRetailerPayment();
  const [tableData, setTableData] = useState([]);
  const { successNotify } = useNotify();
  const { errorNotify } = useNotify();
  const [showInput, setShowInput] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [activeButton0, setActiveButton0] = useState(false);
  const [activeButton1, setActiveButton1] = useState(false);
  const [activeButton2, setActiveButton2] = useState(false);
  const [activeButton3, setActiveButton3] = useState(false);

  //   const { data } = useGetFloor();

  const handleButtonClick = (value) => {
    setValue("discount", value);
  };
  const fetchData = async () => {
    if (menuData) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/order/?invoice_uuid=${menuData}`
      );
      setInvoiceId(response?.data[0]?.uuid);
    }
  };
  useEffect(() => {
    fetchData();
  }, [menuData]);
  useEffect(() => {
    if (isSuccess) {
      successNotify("Order updated successfully");
      onClose();
      const reloadTimeout = setTimeout(() => {
        location.reload();
      }, 1000);
    }
    if (isError) {
      errorNotify(error?.response?.data?.message);
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    setValue("currency", tableInfo?.currency);
    setValue("table", tableInfo?.table);
    setValue("discount", tableInfo?.discount);
    setValue("tax", tableInfo?.tax);
    setValue(
      "invoicedate",
      moment(tableInfo?.invoicedate).format("YYYY-MM-DD")
    );

    setValue("adjustment", tableInfo?.adjustment_amount);

    setValue("guest", tableInfo?.guest);
  }, [tableInfo]);
  useEffect(() => {
    if (getValues("discount") === 0) {
      setActiveButton0(true);
    }
    if (getValues("discount") === 5) {
      setActiveButton1(true);
    }
    if (getValues("discount") === 10) {
      setActiveButton2(true);
    }
    if (getValues("discount") === 15) {
      setActiveButton3(true);
    }
  }, [getValues()]);
  return isLoading ? (
    <Box
      display={"block"}
      pos="absolute"
      h="100%"
      w="100%"
      bgGradient="linear(135deg, rgba(255,255,255,.1),rgba(255,255,255,0))"
      backdropFilter="blur(20px) "
      zIndex={999999}
    >
      <Loading mt="40vh" spinnerSize="lg" textSize="1.5rem" />
    </Box>
  ) : (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {" "}
            <>
              <input
                {...register("uuid")}
                type="hidden"
                defaultValue={invoiceId}
              />
              <LeftSideLabelSelect
                bottomMargin
                labelWidth={"25rem"}
                alignment
                fontWeight="600"
                label="Change Currency"
                register={register}
                registerName={"currency"}
                control={control}
                data={currencyList}
              />

              {/* <Box mt="1rem">
                <label>Disocunt</label>
              </Box> */}
              {/* <Box mt="1rem" display={"flex"} gap={"10px"}>
                <Button
                  type="button"
                  colorScheme={activeButton0 ? "red" : "orange"}
                  onClick={() => {
                    setActiveButton0(true);
                    setActiveButton1(false);
                    setActiveButton2(false);
                    setActiveButton3(false);
                    handleButtonClick("0");
                  }}
                >
                  0%
                </Button>
                <Button
                  type="button"
                  colorScheme={activeButton1 ? "red" : "orange"}
                  onClick={() => {
                    setActiveButton1(true);
                    setActiveButton0(false);

                    setActiveButton2(false);
                    setActiveButton3(false);

                    handleButtonClick("5");
                  }}
                >
                  5%
                </Button>
                <Button
                  colorScheme={activeButton2 ? "red" : "orange"}
                  type="button"
                  onClick={() => {
                    setActiveButton2(true);
                    setActiveButton0(false);
                    setActiveButton1(false);
                    setActiveButton3(false);
                    handleButtonClick("10");
                  }}
                >
                  10%
                </Button>
                <Button
                  type="button"
                  colorScheme={activeButton3 ? "red" : "orange"}
                  onClick={() => {
                    setActiveButton3(true);
                    setActiveButton0(false);
                    setActiveButton1(false);
                    setActiveButton2(false);
                    handleButtonClick("15");
                  }}
                >
                  15%
                </Button>
                <Button type="button" onClick={() => setShowInput(true)}>
                  + Custom
                </Button>
              </Box> */}
              <input {...register("discount")} type="hidden" />
              {showInput && (
                <LeftSideLabelInput
                  w={"25rem"}
                  type="number"
                  bottomMargin
                  register={register}
                  registerName={"discount"}
                  control={control}
                  alignment
                />
              )}

              <LeftSideLabelInput
                w={"25rem"}
                label="Invoice Date"
                type="date"
                bottomMargin
                register={register}
                registerName={"invoicedate"}
                control={control}
                // defaultValue={'2022-12-12'}
                alignment
              />

              <LeftSideLabelInput
                w={"25rem"}
                label="Service Charge(%)"
                type="number"
               
                bottomMargin
                register={register}
                registerName={"tax"}
                control={control}
                alignment
              />

              <LeftSideLabelInput
                bottomMargin
                w={"25rem"}
                label="Adjustments"
                register={register}
                registerName={"adjustment"}
                control={control}
                alignment
              />
            </>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit} variant="ghost">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
