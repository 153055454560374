import React from 'react'
import {
    Flex,
    Text,
    VStack
} from '@chakra-ui/react'
import { LeftSideLabelText } from 'components/Common/LeftSideLabelText'


export default function Highlight({ unit, brand, manufacturer, category }) {

    return (
        <>
            {unit && <LeftSideLabelText mt="--0.5rem" label="Unit" value={unit} />}
            {brand && <LeftSideLabelText mt="--0.5rem" label="Brand" value={brand} />}
            {manufacturer && <LeftSideLabelText mt="--0.5rem" label="Manufacturer" value={manufacturer} />}
            {category && <LeftSideLabelText mt="--0.5rem" label="Category" value={category} />}
        </>
    )
}
