import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import theme from "theme/theme.js";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import LandingLayout from "layouts/Landing";
import store from "redux/store";
import "app.css";
import SignIn from "views/Pages/signin/SignIn";
import Menu from "views/PublicPages/Menu/Menu";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (error?.response?.status === 403) {
        localStorage.setItem("unAuthorized", error.response.status);
        // console.log("error global", error.response.status);
        // window.location.reload();
      }
    },
  }),
});
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />{" "}
      <PersistGate loading={null} persistor={persistor}>
        {" "}
        <ChakraProvider theme={theme} resetCss={false} position="relative">
          <HashRouter>
            <Switch>
              <Route path={`/signin`} component={SignIn} />
              {/* {localStorage.getItem("access_token") && ( */}
              <Route path={`/admin`} component={AdminLayout} />
              {/* )} */}
              <Route path={`/menu/*`} component={Menu} exact={true} />
              <Redirect from={`/`} to="/signin" />
            </Switch>
          </HashRouter>
        </ChakraProvider>
      </PersistGate>
    </QueryClientProvider>
  </Provider>,

  document.getElementById("root")
);
